<template>
  <div>
    <el-card>
      <el-row class="py-4 px-8 items-center justify-between">
        <el-col :span="4">
          <img src="../../assets/images/lungs.png" />
        </el-col>
        <el-col :span="18">
          <div :class="`font-medium text-3xl mb-4 text-[${palette.default}]`" v-html="main.title"></div>
          <div class="font-light text-base" v-html="main.text"></div>
        </el-col>
      </el-row>
    </el-card>

    <div class="mt-4">
      <el-collapse>
        <!-- FMEA Overview -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-outdent" class="mr-6" />FMEA Overview</template>
          <div class="mb-3">
            <span :class="`text-[${palette.default}] font-semibold text-lg`">Radiotherapy (RT)</span> is one of the safest medical disciplines yet, as
            any other complex process is prone to failures and errors that may risk patient safety.
          </div>

          <div class="my-3">
            To minimize the risk of failures and prevent unintended exposure, the European Council Directive 2013/591212 and the International Atomic
            Energy Agency (IAEA) in the Basic Safety Standards, require RT departments to implement risk management methods and integrate risk
            mitigation strategies into their quality management programs.
          </div>

          <div class="my-3">
            <span :class="`text-[${palette.default}] font-semibold text-lg`">Proactive risk assessment</span> (prospective, a priori, or
            <span class="italic">a study of risk</span>) aims to identify weaknesses in a system and apply strategies to mitigate the risks
            <span class="font-semibold">before</span> they turn into errors.
          </div>

          <div class="my-3">
            The <span :class="`text-[${palette.default}] font-semibold text-lg`">AAPM TG-100</span> developed a proactive risk management method based
            on process mapping, typical FMEA, and Fault Tree Analysis (FTA). It is specially designed for use in RT.
          </div>

          <div class="my-3">
            <span :class="`text-[${palette.default}] font-semibold text-lg`">Failure mode (FM)</span> is the way a failure occurs.
          </div>

          <div class="my-3">
            <span :class="`text-[${palette.default}] font-semibold text-lg`">Failure modes and effect analysis (FMEA)</span> is a proactive risk
            assessment method widely used in RT for identifying, assessing, prioritizing, and mitigating risks in the RT process.
          </div>

          <div class="my-3">
            A <span :class="`text-[${palette.default}] font-semibold text-lg`">process map</span> outlines the individual subprocesses and steps of
            the process under investigation in chronological order.
          </div>

          <div class="mt-3">
            <span :class="`text-[${palette.default}] font-semibold text-lg`">FTA</span> is a reactive approach to incidents, yet can be applied
            proactively to identify potential root causes of failures and failure propagation within a process in order to optimize the risk
            mitigation strategies.
          </div>

          <el-divider />
          <!-- Typical FMEA -->
          <div :class="`text-[${palette.default}] font-bold text-2xl mb-2`">Typical FMEA</div>
          <span class="font-semibold">Basic steps</span> for conducting a proactive risk assessment based on the typical FMEA method and process
          mapping:
          <ul class="list-none pl-6">
            <li class="py-1">
              <span class="pr-2">1)</span>Define the process. It may be the whole RT pathway or a subprocess such as treatment planning, treatment
              delivery etc.
            </li>
            <li class="py-1">
              <span class="pr-2">2)</span>Assemble a multidisciplinary team including all professional groups involved in the process.
            </li>
            <li class="py-1">
              <span class="pr-2">3)</span>The team drafts a process map indicating all steps in the process or subprocess in chronological order.
            </li>
            <li class="py-1">
              <span class="pr-2">4)</span>For each step, team members identify the potential FMs and their associated causes and effects. This
              procedure might be challenging especially when a new technique or technology is introduced in the department. Brainstorming, literature
              review, and experience on the process under investigation will determine this procedure.
              <span class="font-semibold">At this point, the iSART FMs database aspires to help the users.</span>
            </li>
            <li class="py-1">
              <span class="pr-2">5)</span>In the typical FMEA method, each FM is scored by team members against 3 risk indices usually on a 10-point
              scale:
            </li>
            <li class="pl-4 py-1"><span class="pr-2">●</span>Severity (S) - Occurrence (O) - Detectability (D)</li>
            <li class="pl-4 py-1">
              <span class="pr-2">●</span>The risk Priority Number (RPN) is the metric used to prioritize the riskiest FMs for mitigation strategies.
              The highest the RPN the riskiest the FM and warrants control. In addition to the RPN, the Severity should also be taken into account
              when designing a mitigation strategy. An FM may have a low RPN and a high Severity, which indicates severe effects in case this FM
              occurs.
            </li>
            <li class="pl-4 py-1">
              <span class="pr-2">●</span>Typically, the average team score for each index is used or a consensus is reached for each index to manage
              scoring differences amongst team members.
            </li>

            <!-- RPN Table -->
            <table class="border-collapse border border-slate-700 ml-8 my-3">
              <thead>
                <tr>
                  <template v-for="(colName, index) in rpnTable.header" :key="index">
                    <th class="border border-slate-700 px-4 py-2">{{ colName }}</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(row, index) in rpnTable.content" :key="index">
                  <tr>
                    <template v-for="(cell, index) in row" :key="index">
                      <td class="border border-slate-700 px-4 py-2">{{ cell }}</td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>

            <!-- Reference -->
            <div class="border-dashed border-2 border-neutral-500 ml-8 p-2 mt-6 mb-3">
              Risk indices S, O, D and typical rating scale. Sarchosoglou AA, Papavasileiou P, Bakas A, Stasinou D, Pappas E. Failure modes in
              stereotactic radiosurgery. A narrative review. Radiography (Lond). 2022 Nov;28(4):999-1009. doi: 10.1016/j.radi.2022.07.007. Epub 2022
              Jul 31. PMID: 35921732
            </div>

            <li class="py-1">
              <span class="pr-2">6)</span>Mitigation strategies are designed to inform the quality management system. An FTA can be used to assist
              optimization of the strategies throughout the entire process. Re-assessment of the RPNs of the FMs under the scope of mitigation
              strategies is essential to assess the effectiveness of the mitigation strategies.
            </li>
          </ul>

          <el-divider />
          <!-- FMEA template -->
          <div :class="`text-[${palette.default}] font-bold text-2xl`">FMEA template</div>
          <table class="border-collapse border border-slate-700 mt-3 mb-6">
            <thead>
              <tr>
                <template v-for="(colName, index) in fmeaProcessTable.header" :key="index">
                  <th class="border border-slate-700 px-2 py-2">{{ colName }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in fmeaProcessTable.content" :key="index">
                <tr>
                  <template v-for="(cell, index) in row" :key="index">
                    <td class="border border-slate-700 py-4">{{ cell }}</td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
          <!-- Reference -->
          <div class="border-dashed border-2 border-neutral-500 p-2 mb-9">
            Huq MS, Fraass BA, Dunscombe PB, Gibbons JP Jr, Ibbott GS, Mundt AJ, Mutic S, Palta JR, Rath F, Thomadsen BR, Williamson JF, Yorke ED. The
            report of Task Group 100 of the AAPM: Application of risk analysis methods to radiation therapy quality management. Med Phys. 2016
            Jul;43(7):4209. doi: 10.1118/1.4947547. PMID: 27370140; PMCID: PMC4985013
          </div>

          <el-divider><p :class="`text-[${palette.default}] font-semibold text-lg`">NOTE</p></el-divider>

          There are variations of the typical FMEA such as the
          <span class="font-semibold">Failure Mode and Effects Criticality Analysis (FMECA)</span> and the
          <span class="font-semibold">Healthcare Failure Mode and Effects Analysis (HFMEA)</span> where a scoring scale of 1-5 or 1-4 is commonly
          used. A criticality number is used (Severity X Occurrence) against a risk matrix to prioritize FMs for mitigation strategies.
        </el-collapse-item>

        <!-- FMs Database Info -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-toggle-on" class="mr-5" />Failure Modes Database Info</template>
          The
          <a
            :class="`cursor-pointer underline underline-offset-2 font-semibold text-lg text-[${palette.lightgray}]`"
            @click="router.push({ name: 'fm-list' })"
            >failure modes database</a
          >
          is a database with potential FMs in radiotherapy process.

          <div class="my-3">
            Data were taken from recently
            <a :class="`cursor-pointer underline underline-offset-2 font-semibold text-[${palette.lightgray}]`" href="#sources">published papers</a>
            and reports from European departments using methodologies such as the Failure Mode and Effect Analysis (FMEA), AAPM TG-100, Failure Mode
            and Effect Criticality Analysis (FMECA) and Healthcare Failure Mode and Effect Analysis (HFMEA).
          </div>

          <ul class="list-none pl-6">
            <li class="py-1"><span class="pr-2">1)</span>The structure of the iSART FMs database is based on the AAPM TG-100.</li>

            <li class="py-1">
              <span class="pr-2">2)</span>"Potential effects" in iSART follow the description of AAPM TG-100: inconvenience; suboptimal plan or
              treatment; wrong dose, dose distribution, location, or volume; very wrong dose, dose distribution, location, or volume and
              non-radiation-related physical injury (see table below).
            </li>

            <table class="border-collapse border border-slate-700 mt-3 mb-6 ml-4">
              <thead>
                <tr>
                  <template v-for="(colName, index) in potentialEffectTable.header" :key="index">
                    <th class="border border-slate-700 p-2">{{ colName }}</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="(row, index) in potentialEffectTable.content" :key="index">
                  <tr>
                    <template v-for="(cell, index) in row" :key="index">
                      <td class="border border-slate-700 p-2">{{ cell }}</td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>

            <!-- Reference -->
            <div class="border-dashed border-2 border-neutral-500 p-2 mb-3 ml-4">
              Huq MS, Fraass BA, Dunscombe PB, Gibbons JP Jr, Ibbott GS, Mundt AJ, Mutic S, Palta JR, Rath F, Thomadsen BR, Williamson JF, Yorke ED.
              The report of Task Group 100 of the AAPM: Application of risk analysis methods to radiation therapy quality management. Med Phys. 2016
              Jul;43(7):4209. doi: 10.1118/1.4947547. PMID: 27370140; PMCID: PMC4985013
            </div>
            <li class="py-1">
              <span class="pr-2">3)</span>In iSART the qualitative description of the severity is the combination of the AAPM TG-100 and FMEA and
              HFMEA and is indicated with the traffic lights colors.
            </li>
          </ul>

          <table class="border-collapse border border-slate-700 ml-10 my-3">
            <thead>
              <tr>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`" rowspan="2">iSART Severity colors</th>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`" colspan="3">Rank</th>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`" colspan="2">
                  Severity (<span class="italic">S</span>)
                </th>
              </tr>
              <tr>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">
                  HFMEA
                  <p>(1-4)</p>
                </th>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">
                  FMECA
                  <p>(1-5)</p>
                </th>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">
                  AAPM TG-100
                  <p>(1-100)</p>
                </th>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">
                  Qualitative
                  <p>(AAPM TG-100)</p>
                </th>
                <th class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">
                  Potential effects - Categorization<br />(AAPM TG-100)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border border-slate-700 px-4 py-2" rowspan="4" :style="`background-color: ${palette.low}`">Low</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="4" :style="`background-color: ${palette.low}`">1</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.low}`">1</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.low}`">1</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.low}`">No effect</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`"></td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.low}`">2</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.low}`">Inconvenience</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.other}`">Inconvenience</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.low}`">2</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.low}`">3</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.low}`">4</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.low}`">Minor dosimetric error</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">Suboptimal plan or treatment</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.moderate}`">Moderate</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.moderate}`">2</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.moderate}`">3</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.moderate}`">5</td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.moderate}`">
                  Limited toxicity or tumor underdose
                </td>
                <td class="border border-slate-700 px-4 py-2" rowspan="4" :style="`background-color: ${palette.other}`">
                  Wrong dose, dose distribution, location, or volume
                </td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.moderate}`">6</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.severe}`" rowspan="2">Severe</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.severe}`" rowspan="2">3</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.severe}`" rowspan="2">4</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.severe}`">7</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.severe}`" rowspan="2">
                  Potentially serious toxicity or tumor underdose
                </td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.severe}`">8</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`" rowspan="2">Catastrophic</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`" rowspan="2">4</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`" rowspan="2">5</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`">9</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`">
                  Possible very serious toxicity or tumor underdose
                </td>
                <td class="border border-slate-700 px-4 py-2" rowspan="2" :style="`background-color: ${palette.other}`">
                  Very wrong dose, dose distribution, location, or volume
                </td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`">10</td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.catastrophic}`">Catastrophic</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" colspan="5"></td>
                <td class="border border-slate-700 px-4 py-2" :style="`background-color: ${palette.other}`">Non radiation related injury (1-10)</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-4 py-2" colspan="5"></td>
                <td class="border border-slate-700 px-4 py-2">Concomitant dose (iSART addition)</td>
              </tr>
            </tbody>
          </table>

          <div class="ml-10 mt-6 mb-3">
            <img src="../../assets/images/screenshot-severity-tooltip.png" />
          </div>

          <div class="mt-6 my-3">
            You can easily navigate between the subprocesses in the major process of RT and also between various steps. The severity of FMs, potential
            causes and effects, and mitigation strategies are displayed as they have been identified in the reports. Occurrence/likelihood and
            detectability indices and RPNs are not displayed, as they are purely subjective to departmental practices.
          </div>

          <div class="my-3">Always keep in mind that an FM with a low RPN may hold a high Severity and needs further investigation.</div>

          <div class="my-3">
            It must be noted that the description of an FM and the accompanying elements (severity, possible causes/impacts and mitigation strategies)
            refer to a specific technique as described in the articles and reports and in most cases take into account their own safety barriers. This
            means that a particular FM can be identified in other techniques and technologies and may have a different severity. Furthermore, in cases
            where information was missing in the data of the articles/reports, the researchers made additions based on their own knowledge and
            experience. The reader can refer to the sources for further reading on specific FMs.
          </div>

          <el-divider>
            <p :class="`text-[${palette.default}] text-lg font-semibold`">IMPORTANT NOTE</p>
          </el-divider>

          <div class="mb-9">
            The FMs database should be used thoroughly as an advisory tool and not as a certified product. Every department functions under its own
            workflow, protocols, and safety barriers and technology that lead every risk assessment study.
          </div>

          <el-divider>
            <div :class="`text-[${palette.default}] text-lg font-semibold`" id="sources">Sources</div>
          </el-divider>
          <div class="mb-9">
            <template v-for="(technique, index) in sources" :key="index">
              <p class="font-semibold mt-2">{{ technique.name }}</p>
              <ul class="list-none pl-6">
                <template v-for="(reference, index) in technique.references" :key="index">
                  <li class="py-1">
                    <span class="pr-2">●</span>{{ reference.title }}
                    <template v-if="reference.url.length">
                      Available from:
                      <a :class="`cursor-pointer underline underline-offset-2 text-[${palette.lightgray}]`" @click="navigateTo(reference.url)">{{
                        reference.url
                      }}</a
                      >.
                    </template>
                  </li>
                </template>
              </ul>
            </template>
          </div>

          <el-divider></el-divider>
          <div :class="`my-3 text-center text-xl font-medium text-[${palette.default}]`">
            We would like to acknowledge the contribution of European departments that shared their reports and thank them for their invaluable input.
          </div>
        </el-collapse-item>

        <!-- Report New FMs -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-book-bookmark" class="mr-6" />Report New Failure Modes</template>
          <div>
            Consider submitting a failure mode or a potential failure you have encountered in routine practice. It will assist in improving patient
            safety.
          </div>
        </el-collapse-item>

        <!-- iSART Evaluation -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-comments" class="mr-5" />iSART Evaluation</template>
          <div>
            Help us improve. Before you exit consider filling in the questionnaire to help us improve this program to fit your needs. Takes only 5
            minutes!
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const main = {
  title: 'Welcome to iSART, <br> An Intelligent Safety Assistant tool for Radiotherapy',
  text: `iSART is a tool that aims at promoting patient safety in radiotherapy by means of the proactive hazard analysis technique called FMEA.`,
};

const palette = {
  default: '#db2777',
  lightgray: '#475569',
  low: '#B4FF9F',
  moderate: '#F9FFA4',
  severe: '#FFD59E',
  catastrophic: '#FFA1A1',
  other: '#F0F0F0',
};

const sources = [
  {
    name: 'IMRT',
    references: [
      {
        title: `Huq MS, Fraass BA, Dunscombe PB, Gibbons JP Jr, Ibbott GS, Mundt AJ, Mutic S, Palta JR, Rath F, Thomadsen BR,
              Williamson JF, Yorke ED. The report of Task Group 100 of the AAPM: Application of risk analysis methods to radiation therapy quality`,
        url: '',
      },
    ],
  },
  {
    name: 'RT pathway',
    references: [
      {
        title: `Pobbiati C, Monturano M, Vavassori A, Gerardi M, Fiore MS, Rondi E, et al. Clinical risk analysis of the
              patient's path in an Advanced Radiotherapy Center (A.R.C.) through F.M.E.A. method. J Biomed Pract [Internet]. 2019 Jun 18 [cited 2020
              Jun 8];3(1):38-69.`,
        url: 'https://www.ojs.unito.it/index.php/jbp/article/view/3376',
      },
    ],
  },
  {
    name: '3D RT',
    references: [
      {
        title: `Gehad Sobhy Muhammad, Iman Aly Sharawy, Hatem Mohamed Abdalla, Khalid Nagib Abdel Hakim and Ghada Refaat
              Meckawy (2021); FAILURE MODE AND EFFECT ANALYSIS FOR THREE DIMENSIONAL RADIOTHERAPY AT AIN SHAMS UNIVERSITY HOSPITAL Int. J. of Adv.
              Res. 9 (Dec). 971-983] (ISSN 2320-5407). DOI: 10.21474/IJAR01/13997.`,
        url: '',
      },
    ],
  },
  {
    name: 'SRS/SRT',
    references: [
      {
        title: `Sarchosoglou AA, Papavasileiou P, Bakas A, Stasinou D, Pappas E. Failure modes in stereotactic radiosurgery.
              A narrative review. Radiography (Lond). 2022 Nov;28(4):999-1009. doi: 10.1016/j.radi.2022.07.007. Epub 2022 Jul 31. PMID: 35921732.`,
        url: '',
      },
    ],
  },
  {
    name: 'SGRT DIBH',
    references: [
      {
        title: `Bright, M., Foster, R.D., Hampton, C.J., Ruiz, J., & Moeller, B. (2022). Failure modes and effects analysis
              for surface-guided DIBH breast radiotherapy. Journal of Applied Clinical Medical Physics, 23.`,
        url: 'https://doi.org/10.1002/acm2.13541',
      },
    ],
  },
  {
    name: 'VMAT',
    references: [
      {
        title: `Gilmore MDF, Rowbottom CG. Evaluation of failure modes and effect analysis for routine risk assessment of
              lung radiotherapy at a UK center. J Appl Clin Med Phys. 2021 May;22(5):36-47. doi: 10.1002/acm2.13238. Epub 2021 Apr 9. PMID: 33835698;
              PMCID: PMC8130239.`,
        url: '',
      },
    ],
  },
  {
    name: 'MRgART',
    references: [
      {
        title: `Klüter, Sebastian & Schrenk, Oliver & Renkamp, C. Katharina & Gliessmann, Stefan & Kress, Melanie & Debus,
              Jürgen & Hörner-Rieber, Juliane. (2021). A practical implementation of risk management for the clinical introduction of online adaptive
              Magnetic Resonance-guided radiotherapy. Physics and Imaging in Radiation Oncology. 17. 53-57. 10.1016/j.phro.2020.12.005.`,
        url: '',
      },
      {
        title: `Liang J, Scripes PG, Tyagi N, Subashi E, Wunner T, Cote N, Chan CY, Ng A, Brennan V, Zakeri K, Wildberger C,
              Mechalakos J. Risk analysis of the Unity 1.5 T MR-Linac adapt-to-position workflow. J Appl Clin Med Phys. 2023 Mar;24(3):e13850. doi:
              10.1002/acm2.13850. Epub 2022 Nov 21. PMID: 36411990; PMCID: PMC10018675.`,
        url: '',
      },
    ],
  },
];

// Wrong scheduling of patient’s programme
const rpnTable = {
  header: ['Risk indices', 'Typical rating scale (1-10)'],
  content: [
    ['Severity (S): the severity of the effect of a particular FM', '1-10 (10: catastrophic)'],
    ['Occurrence (O): the probability of the FM to occur (cause)', '1-10 (10: 100% to occur)'],
    ['Detectability (D): the likelihood that a failure will not be detected', '1-10 (10: never be detected)'],
    ['RPN= S x O x D', '1-1000'],
  ],
};

const fmeaProcessTable = {
  header: [
    'Process Step',
    'Potential Failure Mode',
    'Potential Cause of Failure Mode',
    'Effects of Potential Failure Mode',
    'Current controls',
    'Occurrence - Cause',
    'Detectability of Failure Mode',
    'Severity of Effect from Failure Mode',
    'RPN',
    'Corrective Action',
  ],
  content: [
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
  ],
};

const potentialEffectTable = {
  header: ['Severity term', 'S values', 'Description'],
  content: [
    [
      'Wrong dose distribution',
      '5-8',
      'A failure in the delivery accuracy of the dose distribution that would be expected to increase adverse clinical outcomes (e.g., reduced tumor control or increased likelihood of moderate grade late toxicities) to a level that is statistically detectable in a large patient population. For definitive radiotherapy a variation in the dose to the target or organs at risk of 5%–10% from the practice standard is suggested',
    ],
    [
      'Very wrong dose distribution',
      '9-10',
      'A failure in the delivery accuracy of the dose distribution to an individual patient that is highly likely to cause a serious adverse clinical outcome (e.g., tumor recurrence or grade III/V late toxicity) in that individual patient. For definitive radiotherapy, a threshold of about 10%-20% from the practice standard is suggested, depending on biological sensitivities for the tissues under consideration',
    ],
    [
      'Wrong absolute dose',
      '5-8',
      'A specific type of wrong dose delivery error in which the relative dose distribution is correctly delivered but the entire dose distribution is incorrectly scaled, due to variation in dose to the prescription point or isodose line, e.g., caused by faulty machine calibration or MU calculation error. For definitive radiotherapy, a variation in the dose of 5%-10% is suggested',
    ],
    [
      'Very wrong absolute dose',
      '9-10',
      'A specific type of very wrong dose delivery error in which the relative dose distribution is correctly delivered but the entire dose distribution is incorrectly scaled, due to variation in dose to the prescription point or isodose line, e.g., caused by faulty machine calibration or MU calculation error. For definitive high dose therapy, a threshold of about 10%-20% is suggested',
    ],
    [
      'Wrong location for dose',
      '5-8',
      'A failure in delivering the dose to the correct location that would be expected to increase adverse clinical outcomes (e.g., reduced tumor control or increased likelihood of moderate grade late toxicities) to a level that is statistically detectable in a large patient population. The size of the difference in positioning that constitutes such a failure depends on the anatomy of the target and organs at risk and the defined margins, but, generally, differences of 3–5 mm between the locations of the reference and treated volumes is realistic for standard fractionation treatment',
    ],
    [
      'Very wrong location for dose',
      '9-10',
      'A failure in delivering the dose to the correct location in an individual patient that is highly likely to cause a serious adverse clinical outcome (e.g., tumor recurrence or grade III/V late toxicity) in that individual patient. The size of the difference in positioning that constitutes such a failure depends on the anatomy of the target and organs at risk, but, generally, differences of more than 5 mm between the locations of the reference and treated volumes or inclusion of excessive normal tissue in the treated volume would be classified as “very wrong location”',
    ],
    [
      'Wrong volume',
      '5-8',
      'A failure in delivering the dose to the correct target volume that would be expected to increase adverse clinical outcomes (e.g., reduced tumor control or increased likelihood of moderate grade late toxicities) to a level that is statistically detectable in a large patient population. Volume differences that constitute such a failure depend on the anatomy of the target and organs at risk, and correspond to a marginal miss of the target volume or partial irradiation of an OAR to a sufficiently high dose that statistically detectable increases in complications are likely',
    ],
    [
      'Very wrong volume',
      '9-10',
      'A failure in delivering the dose to the correct target volume in an individual patient that is highly likely to cause a serious adverse clinical outcome (e.g., tumor recurrence or grade III/V late toxicity) in that individual patient. Volume differences that constitute such a failure depend on the anatomy of the target and organs at risk, and correspond to a geographical miss of the target volume or irradiation of an OAR to a dose sufficient to cause a complication or treatment failure in the patients',
    ],
    ['Suboptimal plan', '4', 'A treatment plan with characteristics unlikely to achieve the stated goals'],
    ['Non-radiation-related physical injury', '5-10', 'Injury resulting from causes other than radiation, for example, from physical trauma'],
    [
      'Inconvenience-patient',
      '2-3',
      'Failures that inconvenience the patient, for example, requiring an otherwise unexpected trip to the radiotherapy facility',
    ],
    [
      'Inconvenience-staff or increased cost',
      '1-2',
      'Failures that inconvenience the staff, creating extra work, and cost of treatment or increasing stress',
    ],
  ],
};

const navigateTo = (url) => {
  window.open(url, '_blank').focus();
};
</script>

<style scoped>
:deep(.el-collapse-item__header) {
  @apply px-8 text-xl font-medium;
}

:deep(.el-collapse-item__content) {
  @apply px-16 text-base font-light pt-4;
}

:deep(.el-collapse-item__header) {
  @apply text-[#475569];
}

:deep(.el-divider--horizontal) {
  @apply my-10;
}
</style>
