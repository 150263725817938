<template>
  <el-card class="mb-4">
    <el-row class="pt-4 px-8 items-center justify-between">
      <el-col :span="4">
        <img class="" src="../../assets/images/clipboard.png" />
      </el-col>
      <el-col :span="18">
        <div class="font-medium text-3xl mb-4 text-[#db2777]" v-html="main.title"></div>
        <div class="font-light text-base mb-4" v-html="main.text"></div>
        <!-- <el-button type="success" :icon="Edit" size="large" @click="navigateTo(main.questionnaireUrl)" plain>{{ main.button }}</el-button> -->
      </el-col>
    </el-row>
  </el-card>
  <iframe
    width="640px"
    height="480px"
    src="https://forms.office.com/Pages/ResponsePage.aspx?id=7kOJDHDDs0u6UTIfQG8y7O_xXTS9DGJLoH0hJdoyXE5UNVQzWDhCRkZJTjRTMko3NFYwWFRGS1gyMC4u&embed=true"
    frameborder="0"
    marginwidth="0"
    marginheight="0"
    style="border: none; max-width: 100%; max-height: 100vh; margin: auto"
    allowfullscreen
    webkitallowfullscreen
    mozallowfullscreen
    msallowfullscreen
  >
  </iframe>
</template>

<script setup>
// import { Edit } from '@element-plus/icons-vue';

const main = {
  title: 'We appreciate your feedback!',
  text: `Thanks for visiting the iSART!
        We have a few questions we'd love you to answer. <br>
        This'll take less than 5 minutes, and it'll help us improve the tool. Your responses will be kept anonymous and confidential and you may quit any time. <br>
        Feel free to contact us at ………… if you have any questions.`,
  button: 'Take the survey',
  questionnaireUrl: 'https://forms.office.com/e/n2fTBh6ucB',
};

// const navigateTo = (url) => {
//   window.open(url, '_blank').focus();
// };
</script>
